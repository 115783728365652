import Swiper from 'swiper';

export class BannerSlider {

	public swiper: Swiper;

	constructor(elemId) {
		const elem = document.querySelectorAll(`#${elemId} .swiper-slide`);
		if (elem.length > 1) {
			this.initialize(`#${elemId}`, {
				speed: 400,
				spaceBetween: 10,
				loop: true,
				autoplay: {
					delay: 5000,
				},
				pagination: {
					el: '.slider-nav',
					clickable: true,
					renderBullet: function (index, className) {
						return '<li class="' + className + '"><a href="javascript:void(0);"></a></li>';
					}
				},
			});
		}
	}

	private initialize(elem, options) {
		this.swiper = new Swiper(elem, options);
	}

}
