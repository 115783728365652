export class SpMenu {

	constructor() {
		const spMenuButton = document.getElementById('spMenuButton');
		const gNavi = document.getElementById('gnavi');

		spMenuButton.addEventListener("click", ()=> {
			gNavi.classList.toggle('open');
			spMenuButton.classList.toggle('active');
			document.body.classList.toggle('body_lock');
		});
	}

}
