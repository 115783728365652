import {EventName} from "../const/EventName";

export class SmallHeaderMenu {

	delayRange: number = 0;

	gnaviElem: HTMLElement;

	constructor(gnaviElementId) {
		this.gnaviElem = document.getElementById(gnaviElementId);

		window.addEventListener(EventName.WINDOW_SCROLL, () => {
			this.onScroll(event);
		});
	}

	/**
	 * スクロールに応じて処理を実行するイベントハンドラーです。
	 * @param event
	 */
	onScroll(event: Event) {
		const targetPosition = this.offsetTop(this.gnaviElem) + this.gnaviElem.offsetHeight;

		if (this.scrollTop() > targetPosition + this.delayRange) {
			this.gnaviElem.classList.add('small')
		} else {
			this.gnaviElem.classList.remove('small')
		}
	}

	/**
	 * Windowの高さを取得して返却します。
	 */
	private getWindowHeight(): number {
		return document.documentElement.clientHeight;
	}

	/**
	 * 現在のスクロール位置を取得して返却します。
	 */
	private scrollTop(): number {
		return document.documentElement.scrollTop || document.body.scrollTop;
	}

	/**
	 * 指定した要素のoffset.topの値を取得します。
	 * @param elementName
	 */
	private offsetTop(element): number {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return element.getBoundingClientRect().top + scrollTop;
	}

}
