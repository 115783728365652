export class HashScroll {

	range: number = 50;

	constructor() {
		// URLにハッシュが付加されていればスクロール位置を調整
		const hash = location.hash.replace('#', '');
		if (hash) {
			this.ajustPosition(hash);
		}

		if (/^\/(service|access)(.html|\/|_)$/.test(location.pathname)) {
			this.smoothScroll();
		}
	}

	private smoothScroll () {
		const headH = 50;
		const interval = 10;               //スクロール処理を繰り返す間隔
		const divisor = 8;                  //近づく割合（数値が大きいほどゆっくり近く）
		const range = (divisor / 2) + 1;    //どこまで近づけば処理を終了するか(無限ループにならないように divisor から算出)

		let links;
		if (/^\/(service)(.html|\/)$/.test(location.pathname)) {
			links = document.querySelectorAll('a[href^="/service.html#"], a[href^="/service/#"]');
		} else if (/^\/(access)(.html|\/)$/.test(location.pathname)) {
			links = document.querySelectorAll('a[href^="/access.html#"], a[href^="/access/#"]');
		}

		for (let i = 0; i < links.length; i++) {
			links[i].addEventListener('click', function (e) {
				e.preventDefault();

				let toY;
				let nowY = window.pageYOffset;                       //現在のスクロール値
				const elem = e.currentTarget as HTMLElement;
				const href = elem.getAttribute('href').replace(/\/(service|access)(.html|\/)/, '');          //href取得
				const target = document.querySelector(href);         //リンク先の要素（ターゲット）取得
				if (!target) {
					console.warn("element is not found.");
				}
				const targetRect = target.getBoundingClientRect();   //ターゲットの座標取得
				const targetY = targetRect.top + nowY - headH;        //現在のスクロール値 & ヘッダーの高さを踏まえた座標
				//スクロール終了まで繰り返す処理
				(function doScroll() {
					toY = nowY + Math.round((targetY - nowY) / divisor);  //次に移動する場所（近く割合は除数による。）
					window.scrollTo(0, toY);                              //スクロールさせる
					nowY = toY;                                           //nowY更新

					if (document.body.clientHeight - window.innerHeight < toY) {
						//最下部にスクロールしても対象まで届かない場合は下限までスクロールして強制終了
						window.scrollTo(0, document.body.clientHeight);
						return;
					}
					if (toY >= targetY + range || toY <= targetY - range) {
						//+-rangeの範囲内へ近くまで繰り返す
						window.setTimeout(doScroll, interval);
					} else {
						//+-range の範囲内にくれば正確な値へ移動して終了。
						window.scrollTo(0, targetY);
					}
				})();
			});
		}
	}

	/**
	 * 指定したエレメントに対するスクロール位置を調整します。
	 * @param elementName
	 */
	private ajustPosition(elementName) {
		const elem: HTMLElement = document.getElementById(elementName);

		if (!elem) {
			return;
		}

		setTimeout(()=> {
			const pos = this.offsetTop(elem);
			window.scrollTo(0, pos - this.range);
		}, 100);
	}

	/**
	 * 指定した要素のoffset.topの値を取得します。
	 * @param elementName
	 */
	private offsetTop(element): number {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return element.getBoundingClientRect().top + scrollTop;
	}

}
