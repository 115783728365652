import {EventName} from "../const/EventName";

export class ScrollEffect {

	delta: number;
	windowHeight: number;

	constructor() {
		this.windowHeight = this.getWindowHeight();
		this.delta = this.windowHeight / 4;
	}

	/**
	 * 演出に関するイベント付与、初期設定を行います。
	 */
	initialize() {
		window.addEventListener(EventName.WINDOW_SCROLL, () => {
			this.onScroll(event);
		});
		this.onScroll(null);
	}

	/**
	 * スクロールに応じて処理を実行するイベントハンドラーです。
	 * @param event
	 */
	onScroll(event: Event) {
		const node = document.querySelectorAll('[data-effect]');
		const elems = Array.prototype.slice.call(node,0);
		elems.forEach((elem: HTMLElement) => {
			const elemPosition = this.offsetTop(elem)
			const scrollTop = this.scrollTop();
			const currentPosition = elemPosition - this.windowHeight + this.delta;

			if (scrollTop > currentPosition) {
				elem.classList.add('effect');
			} else {
				elem.classList.remove('effect');
			}
		});
	}

	/**
	 * Windowの高さを取得して返却します。
	 */
	private getWindowHeight(): number {
		return document.documentElement.clientHeight;
	}

	/**
	 * 現在のスクロール位置を取得して返却します。
	 */
	private scrollTop(): number {
		return document.documentElement.scrollTop || document.body.scrollTop;
	}

	/**
	 * 指定した要素のoffset.topの値を取得します。
	 * @param elementName
	 */
	private offsetTop(element): number {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return element.getBoundingClientRect().top + scrollTop;
	}


}
