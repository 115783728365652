import {EventName} from "./const/EventName";
import {ScrollEffect} from "./lib/ScrollEffect";
import {SpMenu} from "./lib/SpMenu";
import {SmallHeaderMenu} from "./lib/SmallHeaderMenu";
import {HashScroll} from "./lib/HashScroll";
import {BannerSlider} from "./lib/BannerSlider";

class Main {
	constructor() {
		const scrollEffect = new ScrollEffect();
		scrollEffect.initialize();

		// スマホメニュー設定
		new SpMenu();

		// スモールヘッダーメニュー
		new SmallHeaderMenu('gnavi');

		// ハッシュスクロール
		new HashScroll();

		// スライダー
		new BannerSlider('bannerSlider');
	}
}

window.addEventListener(EventName.DOM_CONTENT_LOADED, () => new Main());


// foreachのポリフィル（IE11）
if ('NodeList' in window && !NodeList.prototype.forEach) {
	console.info('polyfill for IE11');
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}